import { useEffect } from 'react'

const useOnLoad = (ref, variable, showLoading, setShowLoading) => {
  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        console.log(variable.loading)
        if (ref.current) {
          setShowLoading(true)
        } else {
          setShowLoading(false)
        }
      }, 500)
    } else {
      setShowLoading(false)
    }
  }, [variable])
  return showLoading
}
export default useOnLoad
