import No_Data from '../../assets/img/no_data.png'
import { useTranslation } from 'react-i18next'

const NoData = ({ headerActions, error }) => {
  const { t } = useTranslation()
  return (
    <div className="flex h-full p-36 items-center w-full justify-center ">
      <div className="flex flex-col items-center w-full justify-center ">
        <div className="flex-shrink-0 flex items-center justify-center rounded-full bg-blue-100 mx-0 h-16 w-16">
          <img src={No_Data} className="w-16 h-auto" />
        </div>
        <div className=" mt-0 ml-4 text-left">
          <h3 className="text-sm text-primary leading-6 font-medium" id="modal-headline">
            {t(error)}
          </h3>
        </div>
        <div className="p-4">{headerActions}</div>
      </div>
    </div>
  )
}

export default NoData
