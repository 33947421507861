import { useTranslation } from 'react-i18next'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

const AddButton = ({ onAdd, size = null, title = 'add', disabled, font, id, type }) => {
  const { t } = useTranslation()
  title = `${t('ADD')} ${t(type)}`
  return (
    <div className="w-full flex justify-end ">
      <button
        aria-label="add"
        id={id || 'add-button'}
        onClick={!disabled ? onAdd : null}
        disabled={disabled}
        className={`${'p-2 w-10 h-10 rounded-full'} ${
          disabled ? ' opacity-40 cursor-not-allowed' : 'hover:bg-blue-300'
        } flex items-center justify-center rounded-full border bg-blue-500 shadow-md text-white text-2xl `}
      >
        +
      </button>
    </div>
  )
}
export default AddButton
