import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('auditing')}/api/audit`

const initialState = {
  loading: false,
  data: {},
  error: null
}

export const getSyslog = createAsyncThunk('settings/getSyslog', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/getSyslog`, 'GET')
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data
})

const syslogSlice = createSlice({
  name: 'syslog',
  initialState,
  reducers: {
    reset: () => {
      return initialState
    }
  },
  extraReducers: {
    [getSyslog.pending]: (state) => {
      return { ...state, loading: true }
    },
    [getSyslog.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [getSyslog.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    }
  }
})
const { reducer } = syslogSlice
const reset = syslogSlice.actions.reset
export { reset }
export default reducer
