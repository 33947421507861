import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import SessionSection from '../components/settings/server/SessionSection'
import SmtpSection from '../components/settings/server/SmtpSection'
import CertificateSection from '../components/settings/server/CertificateSection'
import { getCertificatInfoManager } from '../store/certificate/getCertificatInfoManager'
import { getOne } from '../store/settings/getSetting'
import { getSyslog } from '../store/settings/getSyslog'

import Spinner from '../components/spinner/Spinner'
import SyslogSection from '../components/settings/server/SyslogSection'

const General = () => {
  const dispatch = useDispatch()
  const certManagerInfo = useSelector((state) => state.ManagerCertificat)
  const settings = useSelector((state) => state.settings)
  const syslog = useSelector((state) => state.syslog)
  const updateSettings = useSelector((state) => state.updateSettings)

  useEffect(() => {
    if (!settings.data.setting_id && !settings.loading) {
      dispatch(getOne())
    }
    dispatch(getSyslog())
    dispatch(getCertificatInfoManager())
  }, [])
  console.log('ce console log ----->', certManagerInfo)
  return (
    <div className=" space-y-8 overflow-auto h-full p-8 no-scrollbar dark:text-white">
      {(settings.loading || updateSettings.loading || certManagerInfo.loading) && (
        <div className="absolute w-full h-full z-50 top-0 left-0">
          <Spinner version={1} isContainer />
        </div>
      )}
      <CertificateSection data={certManagerInfo.data} />
      <SessionSection session={settings.data?.configs?.session} />
      <SyslogSection data={syslog} />
    </div>
  )
}
export default General
