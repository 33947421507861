import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import DeleteConfirmation from '../components/modals/DeleteConfirmation'
import AddButton from '../components/table/actions/header/AddButton'
import { deleteDeviceUpdate } from '../store/updates/delete'
import UpdateModal from '../components/modals/UpdateModal'
import { getAllUpdate } from '../store/updates/getAll'
import Table from '../components/table/Table'

const Maintenance = () => {
  const [state, setState] = useState({ offset: 1, limit: 10, showModal: false })
  const [itemShouldDeleted, setItemShouldDeleted] = useState(null)
  const dispatch = useDispatch()
  const updates = useSelector((state) => state.updates)
  useEffect(() => {
    dispatch(getAllUpdate({ offset: 0, limit: state.limit }))
  }, [])
  const handlePagination = (page) => {
    dispatch(getAllUpdate({ offset: state.limit * (page - 1), limit: state.limit }))
    setState((prev) => ({ ...prev, offset: page }))
  }

  return (
    <div className="h-full overflow-auto no-scrollbar">
      {state.showModal && (
        <UpdateModal handleClose={() => setState((prev) => ({ ...prev, showModal: false }))} />
      )}
      {itemShouldDeleted && (
        <DeleteConfirmation
          action="scheduled update"
          handleClose={() => setItemShouldDeleted(null)}
          handleConfirm={() => {
            dispatch(deleteDeviceUpdate(itemShouldDeleted))
            setItemShouldDeleted(null)
          }}
        />
      )}
      <div className="m-8 flex flex-col border border-gray-200 rounded-lg dark:bg-slate-800 overflow-hidden">
        <div className="h-full inline-block w-full">
          <div className="overflow-auto">
            {/*table */}
            <Table
              data={updates.data?.rollouts?.content}
              currentPage={state.offset}
              handlePagination={handlePagination}
              type="updates"
              isLoading={updates.loading}
              total={updates.data?.rollouts?.total}
              onDelete={setItemShouldDeleted}
              noDataAction={
                <AddButton
                  onAdd={() => setState((prev) => ({ ...prev, showModal: true }))}
                  disabled={state.updates?.data?.distributions}
                  type="UPDATE"
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Maintenance
