import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useRef, useState } from 'react'
import DatePicker, { getDefaultLocale } from 'react-datepicker'

import Spinner from '../components/spinner/Spinner'
import { getLogs } from '../store/logs/globalLogs'
import LogsView from '../components/logs/LogsView'
import { dateToTimestamp } from '../helpers/date'
import { LogLevel, LogTags } from '../constant'

import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import useOnLoad from '../utils/useOnLoad'

const Logs = () => {
  const { t } = useTranslation()
  const logs = useSelector((state) => state.appLogs)
  const user = useSelector((state) => state.currentUser)
  // First and last day of current month
  const currentDate = new Date()
  const [startDate, setStartDate] = useState(new Date(currentDate.getTime() - 24 * 60 * 60 * 1000))
  const [endDate, setEndDate] = useState(new Date())
  const [level, setLevel] = useState('')
  const [tag, setTag] = useState('')
  const [showLoading, setShowLoading] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!logs.loading) {
      const query = '{orgId="' + user.data.organization_id + '"}|= ``'
      dispatch(
        getLogs({
          query,
          startDate: dateToTimestamp(startDate),
          endDate: dateToTimestamp(endDate)
        })
      )
    }
  }, [])

  useEffect(() => {
    loadLogs()
  }, [tag, level])

  const loadLogs = () => {
    let query = '{orgId="' + user.data.organization_id + '"}|= ``'
    if (tag && level) {
      query =
        '{orgId="' + user.data.organization_id + '", tag="' + tag + '",level="' + level + '"}|= ``'
    } else if (tag || level) {
      query = tag
        ? '{orgId="' + user.data.organization_id + '",tag="' + tag + '"}|=``'
        : '{orgId="' + user.data.organization_id + '",level="' + level + '"}|=``'
    }
    let end = endDate
    let start = startDate
    if (!end) {
      end = new Date()
      setEndDate(end)
    }
    if (startDate.getTime() === end.getTime()) {
      end = new Date(startDate.getTime() + 23 * 60 * 60 * 1000)
    }
    end.setHours(23, 59, 59)
    start.setHours(23, 59, 59)
    dispatch(
      getLogs({
        query,
        startDate: dateToTimestamp(start),
        endDate: dateToTimestamp(end)
      })
    )
  }
  const exportLogs = () => {
    if (logs.data && logs.data.length) {
      const data = logs.data.map(
        (el, index) =>
          `${('000' + (index + 1)).slice(-4)} ${el.date || 'N/A'}  ${el.msg || 'N/A'}  [${
            el.labels.ipAddress || 'N/A'
          }]  [${el.labels.tag}] [${el.labels.level || 'N/A'}]`
      )
      const file = new Blob([data.join('\n')], { type: 'text/plain' })
      // update the download link state
      const element = document.createElement('a')
      element.href = URL.createObjectURL(file)
      element.download = 'lifi-manager-' + Date.now() + '.log'
      // simulate link click
      document.body.appendChild(element)
      element.click()
    }
  }

  const ref = useRef('')

  useEffect(() => {
    ref.current = logs.loading
  }, [logs.loading])

  useOnLoad(ref, logs, showLoading, setShowLoading)

  return (
    <>
      {showLoading && (
        <div className=" h-full w-full z-50 absolute">
          <Spinner />
        </div>
      )}
      <div className="lg:m-8 m-4 h-full flex flex-col overflow-hidden dark:bg-slate-800 ">
        <div className="border w-full rounded-t-md">
          <div className="flex flex-col lg:flex-row lg:space-x-4 space-x-2 lg:px-4 px-2">
            <div className="flex  lg:py-4 lg:space-y-0 space-y-2  py-2 lg:space-x-4 space-x-0 justify-between">
              <div className="md:flex hidden justify-start items-center flex-col xl:flex-row lg:mt-0 md:mt-2">
                <label
                  className="block mb-2 lg:text-sm text-xs font-medium text-gray-900 dark:text-white mr-2"
                  htmlFor="date-picker"
                >
                  {t('DATE')} :
                </label>
                <div>
                  <DatePicker
                    id="date-picker"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="dd/MM/yyyy"
                    filterDate={(date) => date < currentDate}
                    className="focus:outline-none focus:ring bg-gray-50 border lg:text-sm text-xs border-gray-200 text-gray-900 rounded-lg focus:ring-blue-500/50 p-2 pr-4 lg:pr-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                    onChange={(update) => {
                      setStartDate(update[0])
                      setEndDate(update[1])
                    }}
                    isClearable={true}
                  />
                </div>
              </div>
              <div className="flex justify-start items-center flex-col xl:flex-row">
                <label
                  className="lg:block mb-2 lg:text-sm text-xs font-medium text-gray-900 dark:text-white mr-2"
                  htmlFor="tag"
                >
                  {t('FILTER_BY_TAG')} :
                </label>
                <select
                  id="tag"
                  onChange={(e) => setTag(e.target.value)}
                  value={tag}
                  className="focus:outline-none focus:ring bg-gray-50 border border-gray-200 text-gray-900  rounded-lg focus:ring-blue-500/50 p-2 lg:text-sm text-xs dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white w-48"
                >
                  <option value="" />
                  {LogTags.map((el, key) => (
                    <option key={key} value={el.key}>
                      {t(el.label.toUpperCase())}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex justify-start items-center flex-col xl:flex-row">
                <label
                  className="block mb-2 lg:text-sm text-xs font-medium text-gray-900 dark:text-white mr-2 "
                  htmlFor="level"
                >
                  {t('FILTER_BY_LEVEL')} :
                </label>
                <select
                  id="level"
                  onChange={(e) => setLevel(e.target.value)}
                  value={level}
                  className="focus:outline-none focus:ring bg-gray-50 border border-gray-200 text-gray-900 lg:text-sm text-xs rounded-lg focus:ring-blue-500/50 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white w-48"
                >
                  <option value="" />
                  {LogLevel.map((el, key) => (
                    <option key={key} value={el.key}>
                      {t(el.label.toUpperCase())}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="lg:flex-1 flex  lg:w-0 w-full justify-between lg:justify-end">
              <div className="flex md:hidden justify-start text-center items-center space-x-2">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  dateFormat="dd/MM/yyyy"
                  filterDate={(date) => date < currentDate}
                  className="focus:outline-none focus:ring bg-gray-50 border lg:text-sm text-xs border-gray-200 text-gray-900 rounded-lg focus:ring-blue-500/50 p-2 pr-4 lg:pr-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                  endDate={endDate}
                  onChange={(update) => {
                    setStartDate(update[0])
                    setEndDate(update[1])
                  }}
                  isClearable={true}
                />
              </div>
              <div className="flex justify-end lg:justify-center items-center mt-2 lg:m-0">
                <button
                  onClick={exportLogs}
                  type="button"
                  className="text-gray-700 bg-gray-300 hover:bg-blue-200 shadow-md focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2 focus:outline-none"
                >
                  {t('EXPORT')}
                </button>
                <button
                  type="button"
                  onClick={loadLogs}
                  className="text-white bg-blue-600 hover:bg-blue-500 shadow-md focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2"
                >
                  {t('SEARCH')}
                </button>
              </div>
            </div>
          </div>
        </div>

        <LogsView logs={logs} />
      </div>
    </>
  )
}
export default Logs
