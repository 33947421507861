import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import DeleteConfirmation from '../components/modals/DeleteConfirmation'
import AddButton from '../components/table/actions/header/AddButton'
import { getAllDistributions } from '../store/distributions/getAll'
import { deleteDistribution } from '../store/distributions/delete'
import StateInfo from '../common/stateInfo/StateInfo'
import Spinner from '../components/spinner/Spinner'
import Table from '../components/table/Table'

export default function Firmware() {
  const distributions = useSelector((state) => state.distributions)
  const deleteFirmware = useSelector((state) => state.deleteFirmware)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const prevData = useRef()
  const [state, setState] = useState({
    sliceDistribution: [],
    offset: 1,
    itemToBeDeleted: null,
    itemToBeEdit: null,
    showModal: false
  })
  useEffect(() => {
    if (prevData.current && !distributions.loading && !distributions.error) {
      setState((prev) => ({
        ...prev,
        sliceDistribution: distributions.data.slice(0, 10)
      }))
    }
    prevData.current = distributions.loading
  }, [distributions.loading])
  useEffect(() => {
    if (!distributions.data.length && !distributions.loading) {
      dispatch(getAllDistributions())
    } else {
      setState((prev) => ({
        ...prev,
        sliceDistribution: distributions.data.slice(0, 10)
      }))
    }
  }, [])
  const handlePagination = (page) => {
    setState((prev) => ({
      ...prev,
      sliceDistribution: distributions.data.slice(10 * (page - 1), 10 * page),
      offset: page
    }))
  }
  const handleDelete = () => {
    dispatch(
      deleteDistribution({
        distributionId: state.itemToBeDeleted.id,
        moduleId: state.itemToBeDeleted.modules[0]?.id
      })
    )
    setState((prev) => ({ ...prev, itemToBeDeleted: null }))
  }
  return (
    <div className=" space-y-8 overflow-auto h-full p-8 no-scrollbar">
      <div className="flex flex-col border border-gray-200 rounded-lg dark:bg-slate-800 overflow-hidden">
        <div className="h-full inline-block w-full">
          {distributions.loading || deleteFirmware.loading ? (
            <div className="absolute w-full h-full z-50 top-0 left-0">
              <Spinner />
            </div>
          ) : distributions.error ? (
            <div className="h-full flex items-center justify-center">
              <StateInfo
                type="error"
                description={
                  typeof distributions.error === 'object'
                    ? distributions.error.message
                    : distributions.error
                }
              />
            </div>
          ) : (
            <Table
              data={state.sliceDistribution}
              currentPage={state.offset}
              handlePagination={handlePagination}
              type="distributions"
              isLoading={distributions.loading}
              total={distributions.data.length}
              headerActions={
                <AddButton size={'small'} onAdd={() => navigate('/firmware')} type={'FIRMWARE'} />
              }
              noDataAction={<AddButton onAdd={() => navigate('/firmware')} type={'FIRMWARE'} />}
            />
          )}
        </div>
      </div>
    </div>
  )
}
