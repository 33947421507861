import { countLogs } from '../../store/logs/countLogs'
import { useSelector, useDispatch } from 'react-redux'
import { fullDateFormat } from '../../helpers/date'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    }
  },
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true
    }
  }
}

const ChartLogs = () => {
  const { t } = useTranslation()

  const logs = useSelector((state) => state.countLogs)
  const [select, setSelect] = useState('')
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: t('INFO'),
        data: [],
        backgroundColor: '#60a5fa'
      },
      {
        label: t('ERROR'),
        data: [],
        backgroundColor: '#f87171'
      }
    ]
  })
  const dispatch = useDispatch()

  const generateTimestampList = () => {
    const currentDate = new Date()
    const endDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1,
      0,
      0,
      0
    )
    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 7,
      0,
      0,
      0
    )
    const timestampList = []
    let currentTimestamp = startDate.getTime() / 1000

    while (currentTimestamp < endDate.getTime() / 1000) {
      timestampList.push(currentTimestamp)
      currentTimestamp += 60 * 60
    }

    return timestampList
  }

  const parseLogsData = (logsData) => {
    let infoData = []
    let errorData = []
    let valuesInfo = []
    let valuesError = []
    const timestampsList = generateTimestampList()
    let m = 0
    let n = 0
    timestampsList.map((timestamp) => {
      logsData.map((logs) => {
        if (logs.metric.level === 'info') {
          if (m >= logs.values.length) {
            valuesInfo.push(0)
          } else if (logs.values[m][0] === timestamp) {
            valuesInfo.push(logs.values[m][1])
            m++
          } else {
            valuesInfo.push(0)
          }
        } else {
          if (n >= logs.values.length) {
            valuesError.push(0)
          } else if (logs.values[n][0] === timestamp) {
            valuesError.push(logs.values[n][1])
            n++
          } else {
            valuesError.push(0)
          }
        }
      })
    })
    infoData = valuesInfo
    errorData = valuesError
    const labels = timestampsList.map((timestamp) => fullDateFormat(timestamp * 1000))
    return { labels, infoData, errorData }
  }

  const loadLogs = () => {
    if (logs.data.length) {
      const { labels, infoData, errorData } = parseLogsData(logs.data)
      setChartData({
        labels,
        datasets: [
          { label: 'Info', data: infoData, backgroundColor: '#60a5fa' },
          { label: 'Error', data: errorData, backgroundColor: '#f87171' }
        ]
      })
    }
  }

  useEffect(() => {
    if (!logs.loading) {
      const currentDate = new Date()

      const endDate =
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + 1,
          0,
          0,
          0
        ).getTime() * 1000000

      const startDate =
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - 7,
          0,
          0,
          0
        ).getTime() * 1000000

      let query = `count by(level) (rate({orgId="2", level =~"${
        select ? select : 'error|info'
      }"} [1h]))`

      dispatch(
        countLogs({
          query,
          startDate: startDate.toString(),
          endDate: endDate.toString(),
          count: true
        })
      )
    }
  }, [select])

  useEffect(() => {
    loadLogs()
  }, [logs])

  const generateRandomHeights = (count) => {
    const heights = []
    for (let i = 0; i < count; i++) {
      const randomHeight = Math.floor(Math.random() * 100) + 10 // Generate a random number between 10 and 110
      heights.push(randomHeight)
    }
    return heights
  }
  return (
    <div className="h-full border border-gray-200 bg-white rounded-lg dark:bg-slate-800 overflow-hidden shadow-sm">
      <div className="flex justify-between w-full py-1 items-center">
        <p className="text-lg pl-12"> {t('MANAGER_LOG')}</p>

        <div className="pr-12 flex items-center space-x-2">
          <label htmlFor="logs" className="whitespace-nowrap text-sm">
            {t('SELECT_LEVEL')} :
          </label>
          <select
            id="logs"
            className="z-50 bg-gray-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
            onChange={(e) => setSelect(e.target.value)}
          >
            <option value="">{t('INFO_ERROR')}</option>
            <option value="info">{t('INFO')}</option>
            <option value="error">{t('ERROR')}</option>
          </select>
        </div>
      </div>
      <div className="px-10 pb-12 h-full z-50 relative">
        <hr />

        {logs.loading ? (
          <div className="w-full  h-full p-2">
            <div className="flex h-full p-2 animate-pulse justify-center">
              <ul className="flex h-full space-x-1 items-end">
                <li className="w-2 h-3/4 bg-gray-200" />
                <li className="w-2 h-1/2 bg-gray-200" />
                <li className="w-2 h-1/3 bg-gray-200" />
                <li className="w-2 h-2/3 bg-gray-200" />
                <li className="w-2 h-1/2 bg-gray-200" />
                <li className="w-2 h-1/2 bg-gray-200" />
                <li className="w-2 h-1/4 bg-gray-200" />
              </ul>
            </div>
          </div>
        ) : (
          <Bar options={options} data={chartData} />
        )}
      </div>
    </div>
  )
}

export default ChartLogs
