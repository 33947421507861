import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { success, error } from '../requestInfo/requestInfo'
import { getSyslog } from '../settings/getSyslog'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('auditing')}/api/audit`

const initialState = {
  loading: false,
  data: {},
  error: null
}

export const updateSyslog = createAsyncThunk('setting/updateSyslog', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/changeSyslog`, 'POST', data)
  if (res.status === 'error') {
    thunkAPI.dispatch(error(res.message))
    return thunkAPI.rejectWithValue(res.message)
  }
  thunkAPI.dispatch(success())
  thunkAPI.dispatch(getSyslog())
  return res.data
})

const syslogSlice = createSlice({
  name: 'updateSyslog',
  initialState,
  extraReducers: {
    [updateSyslog.pending]: (state) => {
      return { ...state, loading: true }
    },
    [updateSyslog.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [updateSyslog.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    }
  }
})
const { reducer } = syslogSlice
export default reducer
