import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { updateSyslog } from '../../../store/settings/updateSyslog'

import Button from '../../../common/buttons/Button'
import Input from '../../../common/fields/Input'
import { useTranslation } from 'react-i18next'
import { IpValidator, PortValidator } from '../../../helpers/string'

const SyslogSection = ({ data }) => {
  const { t } = useTranslation()
  const [protocol, setProtocol] = useState()
  const [host, setHost] = useState()
  const [port, setPort] = useState()

  const dispatch = useDispatch()
  const [error, setError] = useState({ host: false, port: false })
  useEffect(() => {
    setHost(data?.data?.host)
    setPort(data?.data?.port)
    setProtocol(data?.data?.protocol)
  }, [data])

  const validateIp = (val) => {
    if (!IpValidator(val)) {
      setError((prev) => ({ ...prev, host: true }))
    } else {
      setError((prev) => ({ ...prev, host: false }))
    }
    setHost(val)
  }

  const validatePort = (val) => {
    if (!PortValidator(val)) {
      setError((prev) => ({ ...prev, port: true }))
    } else {
      setError((prev) => ({ ...prev, port: false }))
    }
    setPort(val)
  }

  const handleUpdate = () => {
    if (!error.host && !error.port) {
      dispatch(updateSyslog({ host: host, port: port, protocol: protocol }))
    }
  }

  return (
    <div className="flex flex-col border border-gray-200 rounded-lg dark:bg-slate-800 overflow-hidden">
      <div className="dark:bg-slate-700 bg-gray-200 h-12 flex items-center p-5">
        <h3 className="text-lg">
          {t('CONFIG')} {t('Syslog')}
        </h3>
      </div>
      <div className="p-5">
        <div className="grid grid-cols-4 gap-4  justify-center">
          <label
            htmlFor="type"
            className="block lg:text-sm text-lg leading-5 font-medium text-primary dark:text-white"
          >
            {t('SYSLOG_CONF')} :
          </label>

          <Input
            error={error.host ? t('INCORRECT_IP') : false}
            value={host}
            label={t('SYSLOG_HOST')}
            placeholder={t('SYSLOG_HOST')}
            onChange={(val) => validateIp(val)}
          />
          <Input
            error={error.port ? t('INCORRECT_PORT') : false}
            value={port}
            placeholder={t('PORT')}
            label={t('PORT')}
            onChange={(val) => validatePort(val)}
          />
          <div>
            <label
              htmlFor={'input_protocol'}
              className={
                'block lg:text-sm text-lg leading-5 text-primary dark:text-white font-medium '
              }
            >
              {t('PROTOCOL')}
            </label>
            <select
              id="type"
              onChange={(e) => setProtocol(e.target.value)}
              value={protocol}
              className="mt-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white form-input bg-secondary text-primary text-sm block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none h-10"
            >
              <option value="tcp">{t('TCP')}</option>
              <option value="udp">{t('UDP')}</option>
            </select>
          </div>
        </div>
        <div className="flex justify-end space-x-4 mt-5">
          <Button title={t('SAVE')} onClick={handleUpdate} />
        </div>
      </div>
    </div>
  )
}
export default SyslogSection
